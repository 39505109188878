:root {
    --color1: #eeb5eb;
    --color2: #c26dbc;
    --color3: #c8f4f9;
    --color4: #3cacae;
    --color5: #dee2ff;
    --color-gray: #4a4a4a;
    --color-gray-bright: #505050;
    --color-background: #e8e8e8;

    --main-font: 'Roboto', sans-serif;
    --header-font: 'Roboto Mono', monospace;
}

body {
    font-size: 18px;
    background-color: #e8e8e8;
    background-color: var(--color-background);
    font-family:'Roboto', sans-serif;
    font-family:var(--main-font);
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto Mono', monospace;
    font-family: var(--header-font);
}

@media (max-width: 640px) {
    .content h1 {
        font-size: 1.5em;
    }

    .content h2 {
        font-size: 1.4em;
    }

    .content h3 {
        font-size: 1.3em;
    }
}

.footer {
    padding: 3rem 1.5rem 1rem;
}

/* convenience styles */
.spacer-little {
    margin-bottom: 20px;
}
  
.spacer-large {
    margin-bottom: 60px;
}

.center {
    text-align: center;
}

/* header */

.navbar-item.logo {
    padding: 0;
}

.navbar-item.logo img {
    max-height: 2.75rem;
}

/* tables */

.table__wrapper {
    overflow-x: auto;
}

/* All styles */ 

.container__main {
    padding-top: 2rem;
}

.content__nogroup {
    padding: 0 1rem 0 1rem;
}

.content__group {
    background-color: white;
    padding: 1rem;
    margin-bottom: 1rem;
}

.game__main-time {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    background-color: #3cacae;
    background-color: var(--color4);
    color: white;
    padding: 10px;
}

@media screen and (max-width: 640px) {
    .game__main-time {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
    }
}

.game__info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.game__info-cover {
    margin-right: 1em;
}

@media (max-width: 640px) {
    .game__info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
    }
    .game__info-cover img {
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding-bottom: 10px;
    }
}

.game__info-cards {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: stretch;
            align-items: stretch;
}

.game__card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    color: #4a4a4a;
    color: var(--color-gray);
    display: block;
    padding: 1.25rem;
    margin-bottom: 0.5rem
    /* max-width: 360px;
    width: 360px; */
}

.game__card .tag {
        margin-bottom: 10px;
    }

.game__card-main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.game__card-tags {
    margin-bottom: 0.5em;
}

.game__main-time-item {
    text-align: center;
}

.game__main-time-item-header {
    border-bottom: 1px solid;
}

.game__thumb {
    margin-right: 20px;
}

@media (min-width: 641px) {
    .game__thumb {
        width: 90px;
    }
}

.game__preview {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    color: #4a4a4a;
    color: var(--color-gray);
    display: block;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: stretch;
            align-items: stretch;
}

.game__card-header {
    background-color: #4a4a4a;
    border-radius: 0px;
    padding: 0.75em 1em;
}

.game__rating {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
}

.game__rating-count {
    font-size: 80%;
}

.game__videos {
    overflow: scroll;
    width: 100%;
    white-space: nowrap;
    background-color: white;
    margin-bottom: 1rem;
    -webkit-overflow-scrolling: touch;
}

.game__single-video {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.game__single-video-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
}

.game__similar {
    overflow: scroll;
    width: 100%;
    white-space: nowrap;
}

.game__similar .game__card {
    background-color: white;
    margin-bottom: 1rem;
}

/* buttons */

.button__link {
    background-color: #c26dbc;
    background-color: var(--color2);
    border-color: transparent;
    color: #fff;
}

/* Search input */

.rounded_field {
    min-width: 250px;
    border-radius: 20px;
    border: 1px solid #434343;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

form#searchgame {
    -webkit-box-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

form#searchgame input {
        border: 0;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 16px;
        -webkit-box-flex: 1;
                flex: 1; 
    }

/******* Forms ********/

.form__select-input-cancel {
    margin-left: 1em;
    padding-top: 0.5em;
}

/* navigation */
.navigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center
}
.navigation .tag {
        margin: 0px 20px 0px 20px;
    }

/* rating */

.circle__wrapper {
    position: relative;
}

.circle__rating {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.circle__content {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 50px;
    font-size: 30px;
    box-sizing: border-box;
    text-align: center;
}

/* games-search */

@media (min-width: 641px) {
    .games-search__form {
        width: 50%;
    }
}